import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  data: [],
};

export const vehicleSlice = createSlice({
  name: 'mega',
  initialState,
  reducers: {
    // TODO: Add reducers here
  },
});

// export const {  } = vehicleSlice.actions;

export default vehicleSlice.reducer;
