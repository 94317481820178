import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import ReactGA from 'react-ga';

const trackPage = (page: string) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const RouteComponent: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    trackPage(window.location.pathname + window.location.search);
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
    </Routes>
  );
};

const AppRouter: React.FC = () => {
  return (
    <Router>
      <RouteComponent />
    </Router>
  );
};

export default AppRouter;