import React from 'react';
import AppRouter from '../../Routes/AppRouter';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import { store } from '../../store';
import theme from '../../themes/dark-theme';
import ReactGA from 'react-ga';
import { AiAssistantButton, AiAssistantProvider } from '@sista/ai-assistant-react';

const App: React.FC = () => {
  // Google Analytics
  ReactGA.initialize('G-X8TNPLN9RZ');
  
  const customStateColors = {
    STATE_IDLE: '#0b7b52', 
    // STATE_LISTENING_START: '#F64A7B', // Bright Pink
    // STATE_THINKING_START: '#4ac2f6', // Sky Blue
    // STATE_SPEAKING_START: '#4af67f', // Light Green
};

  return (
    <React.StrictMode>
      <AiAssistantProvider apiKey="pk-sista-b5979d93-1b94-4d6b-9e4c-1d5e0172a8cb">
        <ChakraProvider theme={theme}>
          <CSSReset />
          <Provider store={store}>
            <AppRouter />
            <AiAssistantButton stateColors={customStateColors} />;
          </Provider>
        </ChakraProvider>
      </AiAssistantProvider>
    </React.StrictMode>
  );
};

export default App;
