import React, { useState, useEffect } from "react";
import { motion } from 'framer-motion';

const MouseFollower: React.FC = () => {
  const [point, setPoint] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handlePointerMove = ({ clientX, clientY }: MouseEvent) => {
      setPoint({ x: clientX, y: clientY });
    };

    window.addEventListener("pointermove", handlePointerMove);

    return () => window.removeEventListener("pointermove", handlePointerMove);
  }, []);

  const { x, y } = point;

  return (
    <motion.div
      initial={{ x: 0, y: 0, position: "fixed", zIndex: 1 }} // Keep the original design's zIndex to ensure it's behind major components
      animate={{ x, y }}
      transition={{
        type: "spring",
        damping: 3, // Use the animation's damping from the example you provided
        stiffness: 50, // Use the animation's stiffness from the example you provided
        restDelta: 0.001 // Use the animation's restDelta from the example you provided
      }}
      style={{ pointerEvents: 'none', opacity: 0.5 }} // Keep the original design's reduced opacity for a more background-like appearance
    >
      <motion.div
        style={{
          position: 'absolute',
          width: '30px',
          height: '30px',
          borderRadius: '50%',
          border: '2px solid #0d9e6c',
          backgroundColor: '#0b7b52',
          boxShadow: '0 0 20px #0d9e6c',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'visible',
        }}
      >
        <motion.span
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            backgroundColor: 'rgba(255, 255, 255, 0.2)', // Further reduced opacity for the inner element
          }}
          animate={{
            scale: [1, 0.1, 1],
          }}
          transition={{
            repeat: Infinity,
            duration: 4,
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export default MouseFollower;
