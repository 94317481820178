import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { ReactLenis } from '@studio-freight/react-lenis';
import MouseFollower from '../components/common/MouseFollower';

// Dynamic imports for the sections
const SectionComponents = {
  LandingSection: React.lazy(() => import('../components/Sections/LandingSection')),
  // About: React.lazy(() => import('../components/Sections/AboutSection')),
};

const HomePage: React.FC = () => {
  // Define ReactLenis options, ensuring autoRaf is true or omitted (since it's true by default)
  const lenisOptions = {
    lerp: 0.1, // Smoothness of the scrolling (lower is smoother)
    smooth: true, // Enable smooth scrolling
    direction: 'vertical', // Scrolling direction
    multiplier: 1.5, // Speed of the scrolling
  };

  return (
    // Main wrapper with ReactLenis for smooth scrolling
    <ReactLenis options={lenisOptions} root>
      <Box>
        <MouseFollower />
        <Box position="fixed" w="full" h="full" zIndex="-1" overflow="hidden">
          <video
            src={`${process.env.PUBLIC_URL}/assets/videos/back-matrix.mp4`}
            poster={`${process.env.PUBLIC_URL}/assets/images/matrix.jpg`} // fallback image
            autoPlay
            loop
            muted
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              filter: 'brightness(20%)',
            }}
          />
        </Box>
        <VStack bg="transparent">
          <React.Suspense fallback={<Box color="primary.700">Awesome Site Loading...</Box>}>
            {Object.values(SectionComponents).map((Section, index) => (
              <Section key={index} />
            ))}
          </React.Suspense>
        </VStack>
      </Box>
    </ReactLenis>
  );
};

export default HomePage;
