import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from './components/App/App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

if (process.env.NODE_ENV !== 'production') {
  reportWebVitals(console.log);
}
