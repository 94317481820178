import { extendTheme } from '@chakra-ui/react';


const colors = {
  primary: {
    400: '#2cd59c',
    500: '#14b887',
    600: '#0d9e6c',
    700: '#0b7b52',
  },
  secondary: {
    400: '#FFA500',
    500: '#FF8C00',
    600: '#FF7F00',
    700: '#FF4500',
  },

  black: {
    400: '#0D0208',
    500: '#0D0208',
  },
  white: {
    400: '#d1d5db',
    500: '#7b7c84',
  },
  gray: {
    400: '#45454f',
    500: '#343541',
  }
};

const fonts = {
  default: "'Roboto Mono', monospace",
  // heading: "'Roboto Mono', monospace",
  // body: "'Roboto Mono', monospace",
};

const styles = {
  global: {
    'html, body': {
      margin: 0,
      padding: 0,
      color: 'gray.600',
      lineHeight: 'tall',
      fontFamily: 'default',
    },
    a: {
      color: 'teal.500',
    },
  },
};

const theme = extendTheme({ colors, fonts, styles });

export default theme;